.react-slideshow-container {
    width: 100%;
    height: 100%;
}

.react-slideshow-container .default-nav  {
    margin-left: 8px;
    margin-right: 8px;
    background-color: rgb(224, 224, 224);
}
.react-slideshow-container .default-nav svg {
    fill: currentColor;
    color: #D761BA;
}

.react-slideshow-fadezoom-wrapper {
    height: 100%;
    width: 100%;

}

.react-slideshow-fadezoom-images-wrap {
    height: 100%;
    width: 100%;

}